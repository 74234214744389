<template>
  <v-card>
    <v-card-title class="align-start">
      <span>Overview by Type</span>
      <v-spacer></v-spacer>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <vue-apex-charts
            id="chart-sales-overview"
            :options="chartOptions"
            :series="chartData"
            class="h-full d-flex align-center"
          ></vue-apex-charts>
        </v-col>

        <v-col
          cols="12"
          sm="6"
          class="d-flex flex-column justify-center"
        >
          <div class="d-flex align-center">
            <v-avatar
              class="v-avatar-light-bg primary--text"
              rounded
              size="40"
            >
              <v-icon
                size="30"
                color="primary"
              >
                {{ icons.mdiImageFilterHdr }}
              </v-icon>
            </v-avatar>
            <div class="ms-4 d-flex flex-column">
              <p class="text--primary mb-0 text-base">
                Most of a single type
              </p>
              <span class="text--primary font-weight-semibold text-xl">{{ maxType.name }} <v-icon>{{ icons.mdiEqual }}</v-icon> {{ maxType.count }}</span>
            </div>
          </div>

          <v-divider class="my-6"></v-divider>

          <table class="sales-overview-stats-table">
            <tr
              v-for="(inCols, idx) in resultingCols"
              :key="idx"
            >
              <td
                v-for="(col, index) in inCols"
                :key="index"
              >
                <div class="mb-0">
                  <div class="stats-dot primary d-inline-block rounded-circle me-2"></div>
                  <span>{{ col.name }}</span>
                </div>
                <span class="text-base text--primary font-weight-semibold ms-4">{{ col.count }}</span>
              </td>
            </tr>
          </table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { addAlpha, getVuetify } from '@core/utils'
import { mdiCurrencyUsd, mdiDotsVertical, mdiEqual, mdiImageFilterHdr } from '@mdi/js'

export default {
  components: {
    VueApexCharts: () => import('vue3-apexcharts'),
  },
  props: {
    statstype: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const $vuetify = getVuetify()
    const types = props.statstype

    const chartOptions = {
      labels: ['Apparel', 'Electronics', 'FMCG', 'Other Sales', 'Alt'],
      colors: [
        $vuetify.theme.currentTheme.primary,
        addAlpha($vuetify.theme.currentTheme.primary, 0.7),
        addAlpha($vuetify.theme.currentTheme.primary, 0.3),
        addAlpha($vuetify.theme.currentTheme.secondary, 0.1),
      ],
      chart: {
        type: 'donut',
        sparkline: {
          enabled: true,
        },
      },
      plotOptions: {
        pie: {
          donut: {
            size: '70%',
            labels: {
              show: true,
              name: {
                fontSize: '14px',
                offsetY: 25,
              },
              value: {
                fontSize: '2.125rem',
                fontWeight: 600,

                offsetY: -15,
                formatter(value) {
                  return `${value}k`
                },
              },
              total: {
                show: true,
                label: 'Materials',
                color: 'rgba(94, 86, 105, 0.68)',
                formatter(value) {
                  return `${value.globals.seriesTotals.reduce((total, num) => total + num)}`
                },
              },
            },
          },
        },
      },
    }

    let maxType = {}
    let tmpCols = []
    const resultingCols = []
    const chartData = []
    props.statstype.forEach((itm, idx) => {
      // Resulting Columns
      if (idx % 2 === 0) {
        if (tmpCols.length > 0) {
          resultingCols.push(tmpCols)
        }
        tmpCols = []
      }
      tmpCols.push(itm)

      // Chart Data
      chartData.push(itm.count)

      // Get Max Type
      if (maxType.count === undefined) {
        maxType = itm
      } else if (itm.count > maxType.count) {
        maxType = itm
      }
    })

    if (tmpCols.length > 0) {
      resultingCols.push(tmpCols)
    }

    return {
      chartOptions,
      chartData,
      resultingCols,
      types,
      maxType,
      icons: {
        mdiDotsVertical,
        mdiCurrencyUsd,
        mdiImageFilterHdr,
        mdiEqual,
      },
    }
  },
}
</script>

<style lang="scss">
#chart-sales-overview {
  .apexcharts-canvas {
    .apexcharts-text {
      &.apexcharts-datalabel-value {
        font-weight: 600;
      }
      &.apexcharts-datalabel-label {
        font-size: 1rem;
      }
    }
  }
}

.sales-overview-stats-table {
  width: 100%;
  td {
    padding-bottom: 1rem;
  }

  .stats-dot {
    padding: 0.33rem;
  }

  // Set opacity of dots
  tr {
    &:nth-of-type(1) {
      td:nth-of-type(2) {
        .stats-dot {
          opacity: 0.7;
        }
      }
    }
    &:nth-of-type(2) {
      td:nth-of-type(1) {
        .stats-dot {
          opacity: 0.5;
        }
      }
      td:nth-of-type(2) {
        .stats-dot {
          opacity: 0.15;
        }
      }
    }
  }
}
</style>
