<template>
  <v-card>
    <v-card-title class="align-start">
      <span class="font-weight-semibold">Material Statistics</span>

      <v-spacer></v-spacer>
    </v-card-title>

    <v-card-subtitle class="mb-7">
      <span class="font-weight-semibold text--primary me-1">Broken down by published / sold</span>
    </v-card-subtitle>

    <v-card-text>
      <v-row>
        <v-col
          v-for="data in statisticsData"
          :key="data.title"
          cols="6"
          sm="4"
          class="d-flex align-center"
        >
          <v-avatar
            size="44"
            :color="resolveStatisticsIconVariation (data.title).color"
            rounded
            class="elevation-1"
          >
            <v-icon
              dark
              color="white"
              size="30"
            >
              {{ resolveStatisticsIconVariation (data.title).icon }}
            </v-icon>
          </v-avatar>

          <div class="ms-3">
            <p class="text-xs mb-0">
              {{ data.title }}
            </p>
            <h3 class="text-xl font-weight-bold">
              {{ data.total }}
            </h3>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiAccountOutline, mdiCurrencyUsd, mdiPackageVariantClosed, mdiShare } from '@mdi/js'

export default {
  props: {
    statsdata: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const statisticsData = props.statsdata

    const resolveStatisticsIconVariation = data => {
      if (data === 'Published') return { icon: mdiShare, color: 'primary' }
      if (data === 'Sold') return { icon: mdiCurrencyUsd, color: 'success' }
      if (data === 'Total') return { icon: mdiPackageVariantClosed, color: 'info' }

      return { icon: mdiAccountOutline, color: 'success' }
    }

    return {
      statisticsData,
      resolveStatisticsIconVariation,
    }
  },
}
</script>
